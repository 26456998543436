import React, { useCallback, useEffect, useState } from 'react'
import './App.sass'

import { BASE } from './basedata/constants'


function App() {

  

  const verifyBiometry = (token: any) => {
    

    fetch(`${BASE.api.base_url}${BASE.api.handle_click}/${token}`, {
      method: 'GET',
      headers: {
        Authorization: BASE.api.token,
        'x-api-key': BASE.api.api_key
      },
    })
      .then((resg) => resg.json())
      .then((response) => {
        window.location.href = response.callback
      })
      .catch((error) => {
        window.location.href = 'https://bepass.com.br/?utm=bredirect'
        return false
      })
  }

  let counter = 0;
  useEffect(() => {

    if(counter === 0){
      counter++;

      const path = window.location.pathname.split(/\/(.*)/).join('').split(/\/(.*)/)
      let token = window.location.search

      if(token){
        token = token.split('?token=')[1]
        verifyBiometry(`${path[0]}/${encodeURIComponent(token) || 0}`)
      }else{
        verifyBiometry(`${path[0]}/${encodeURIComponent(path[1]) || 0}`)
      }
    }
  

  }, [])

  return (<></>
  )
}

export default App
